import { SourceType } from "generated/requests/pos";

const PICKUP = "PICKUP";
const CARRY_OUT = "CARRY_OUT";
const SHIPPING = "SHIPPING";
const DELIVERY = "DELIVERY";
const KIOSK = "KIOSK";
const CATERING = "CATERING";
const TOPPERS = "TOPPERS";
const CORPORATE_GIFTING = "CORPORATE_GIFTING";
const SUBSCRIPTIONS = "SUBSCRIPTIONS";

const GIFTCARD = "giftcard";
const VOUCHER = "voucher";

const PICKUP_INTERVAL = 15;
const DELIVERY_INTERVAL = 30;

const COOKIE_FLAVOR = "COOKIEFLAVOR";
const ICECREAM_FLAVOR = "ICECREAMFLAVOR";
const GIFT_WRAPPING = "GIFTWRAPPING";
const HAND_PIE = "HAND_PIE";
const MINI_COOKIE_FLAVOR = "MINI_COOKIE_FLAVOR";
const PREFILL_COOKIE_FLAVOR = "PREFILL_COOKIE_FLAVOR";
const PREFILL_WEEKLY_MENU = "PREFILL_WEEKLY_MENU";

const ORDER_OPTIONS = [PICKUP, DELIVERY, SHIPPING, SourceType.CorporateGifting];

const ORDER_OPTIONS_TITLES = {
  CARRY_OUT: "Store Pickup",
  SHIPPING: "Shipping",
  DELIVERY: "Delivery",
  KIOSK: "Kiosk",
  CATERING: "Catering Pickup"
};

const ORDER_OPTIONS_IMAGES = {
  CARRY_OUT: "store-icon",
  SHIPPING: "grey-shipping-icon",
  DELIVERY: "grey-delivery-icon",
  CATERING: "grey-catering-icon",
  CORPORATE_GIFTING: "gray-gifting-icon"
};

export const DEFAULT_TIP = 200;
export const DEFAULT_TIP_PERCENTAGE = 0.15;

const INITIAL_ORDER = {
  type: ORDER_OPTIONS[0],
  where: null, //{store: *if available, address: *if available}
  when: null,
  what: null,
  tip: DEFAULT_TIP,
  tipPercentage: DEFAULT_TIP_PERCENTAGE, //if smart tipping, use 15% as default
  paymentMethods: {
    cash: [],
    card: [],
    accounts: []
  },
  status: ""
};

//processing -> capturing -> "thankyou"
const CHECKOUT_STATUS = {
  processing: "PROCESSING",
  capturing: "CAPTURING"
};

const USE_MAPBOX = true;
const MAPBOX_TOKEN =
  "pk.eyJ1IjoiY3J1bWJsY29va2llcyIsImEiOiJja2E0NnplMXMwMmt2M2trZjRqdHN5eWt3In0.nlsK2cApebkhG19zWGrEaA"; //public token
const DEFAULT_TIMEZONE = "America/Chicago";

const SHIPPING_TIMEZONE = "America/Boise";

const CATERING_OPTIONS = {
  regular: "CATERING_REGULAR_FLAVORS",
  regular200: "CATERING_REGULAR_TWO_HUNDRED_PLUS_FLAVORS",
  mini: "CATERING_MINI_FLAVORS",

  packagingOptions: "CATERING_PACKAGING_OPTIONS",

  packagingOption: "CATERING_PACKAGING_OPTION",
  giftingOption: "CATERING_PACKAGING_GIFT_OPTION"
};

export const ELEMENT_IDS = {
  cateringDateSelector: "_catering-date-selector",
  locationSelector: "_where-selector",
  whenSelector: "_when-selector",
  catNav: "_cat-nav"
};

export const SMART_TIPPING_POINT = 1500;

export const DEFAULT_MINSELECTION = 50;

const DELIVERY_PROVIDER_STATUS = {
  WAITING_ASSIGNMENT: "WAITING_ASSIGNMENT",
  ASSIGNED: "ASSIGNED",
  ASSIGNED_AND_ENROUTE_TO_PICKUP: "ASSIGNED_AND_ENROUTE_TO_PICKUP",
  APPROACHING_PICKUP: "APPROACHING_PICKUP",
  PICKED_UP: "PICKED_UP",
  PICKED_UP_AND_ENROUTE_TO_DROPOFF: "PICKED_UP_AND_ENROUTE_TO_DROPOFF",
  APPROACHING_DROPOFF: "APPROACHING_DROPOFF",
  DELIVERED: "DELIVERED",
  CANCELLED: "CANCELLED",
  RETURNED: "RETURNED",
  FAILED: "FAILED"
};

const DOOR_DASH_DELIVERY_STATUSES = {
  scheduled: "scheduled",
  assigned: "assigned",
  picked_up: "picked_up",
  delivered: "delivered",
  cancelled: "cancelled"
};

const DOOR_DASH_DASHER_STATUSES = {
  unassigned: "unassigned",
  accepted: "accepted",
  arrived_at_store: "arrived_at_store",
  picked_up: "picked_up",
  arrived_at_consumer: "arrived_at_consumer",
  dropped_off: "dropped_off"
};

const CRUMBL_DELIVERY_STATES = {
  QUEUED: "QUEUED",
  ACTIVE: "ACTIVE",
  COMPLETE: "COMPLETE",
  CANCELED: "CANCELED"
};

const FULFILLMENT_STATUSES = {
  PENDING: "PENDING",
  PAID: "PAID",
  PREPARING: "PREPARING",
  PREPARED: "PREPARED",
  DELIVERED: "DELIVERED"
};

export const LARGE_ORDER = 12000;

export const MYSTERY_COOKIE_ID = "37ab4760-7e09-11ec-b436-b90f8a5683db:Cookie";
export const MYSTERY_COOKIE_IMAGE = "/images/mystery-cookie.gif";

export {
  CARRY_OUT,
  CATERING,
  CATERING_OPTIONS,
  CHECKOUT_STATUS,
  COOKIE_FLAVOR,
  CORPORATE_GIFTING,
  CRUMBL_DELIVERY_STATES,
  DEFAULT_TIMEZONE,
  DELIVERY,
  DELIVERY_INTERVAL,
  DELIVERY_PROVIDER_STATUS,
  DOOR_DASH_DASHER_STATUSES,
  DOOR_DASH_DELIVERY_STATUSES,
  FULFILLMENT_STATUSES,
  GIFTCARD,
  GIFT_WRAPPING,
  HAND_PIE,
  ICECREAM_FLAVOR,
  INITIAL_ORDER,
  KIOSK,
  MAPBOX_TOKEN,
  MINI_COOKIE_FLAVOR,
  ORDER_OPTIONS,
  ORDER_OPTIONS_IMAGES,
  ORDER_OPTIONS_TITLES,
  PICKUP,
  PICKUP_INTERVAL,
  PREFILL_COOKIE_FLAVOR,
  PREFILL_WEEKLY_MENU,
  SHIPPING,
  SHIPPING_TIMEZONE,
  SUBSCRIPTIONS,
  TOPPERS,
  USE_MAPBOX,
  VOUCHER
};
