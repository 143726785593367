import { FetchStoreBySlugFullQuery } from "@/generated/requests/backend";
import {
  AllPaymentMethodsInput,
  CaptureOrderUpsert,
  MoneyInput,
  Order,
  OrderItemInput,
  OrderOrigin,
  OrderPaymentIntent,
  OrderVoucherInput,
  PaymentMethodType,
  SourceBusinessHoursDocument,
  SourceType,
} from "@/generated/requests/pos";
import { Address as AddressForOrder, PublicStoreInfo } from "@/generated/requests/services";
import dayjs from "dayjs";
import { Service, client } from "lib/apollo";
import { NextRouter } from "next/router";
import IconCarFilled from "../../atoms/Icons/CarFilled";
import IconCarRegular from "../../atoms/Icons/CarRegular";
import IconLocationPinFilled from "../../atoms/Icons/LocationPinFilled";
import IconLocationPinRegular from "../../atoms/Icons/LocationPinRegular";
import IconStoreFilled from "../../atoms/Icons/StoreFilled";
import IconStoreRegular from "../../atoms/Icons/StoreRegular";
import { OrderDetailsType, TimeSlot, getSourceTypeFromUrlOrderType } from "../OrderContext/helpers";

export const getTimeAndAddressLabelsForOrder = ({
  order,
  orderTimeSlot,
  isIconFilled = true,
  t,
  router,
  deliveryAddress,
  sourceType,
  store,
}: {
  order: Order;
  orderTimeSlot: string;
  isIconFilled?: boolean;
  t: (key: string) => string;
  router: NextRouter;
  deliveryAddress: any;
  sourceType?: SourceType;
  store?: PublicStoreInfo;
}) => {
  // Order type header info
  const type = sourceType || order?.source?.type || getSourceTypeFromUrlOrderType(router.query.type as string);
  const labelsByTypeMap = {
    [SourceType.Delivery]: {
      IconForOrderType: isIconFilled ? IconLocationPinFilled : IconLocationPinRegular,
      typeLabel: t("order:delivery"),
      locationLabel: deliveryAddress?.name,
      addressLabel: [
        deliveryAddress?.lineOne,
        deliveryAddress?.lineTwo,
        deliveryAddress?.lineThree,
        deliveryAddress?.formattedSubtitle,
      ].join(" "),
    },
    [SourceType.CarryOut]: {
      typeLabel: t("order:carry_out"),
      IconForOrderType: isIconFilled ? IconStoreFilled : IconStoreRegular,
      locationLabel: store?.name || order?.store?.storeName,
      addressLabel: store?.address || order?.store?.storeAddress,
    },
    [SourceType.Pickup]: {
      typeLabel: t("order:pickup"),
      IconForOrderType: isIconFilled ? IconCarFilled : IconCarRegular,
      locationLabel: store?.name || order?.store?.storeName,
      addressLabel: store?.address || order?.store?.storeAddress,
    },
    [SourceType.Catering]: {
      IconForOrderType: isIconFilled ? IconStoreFilled : IconStoreRegular,
      locationLabel: store?.name || order?.store?.storeName,
      addressLabel: store?.address || order?.store?.storeAddress,
      typeLabel: t("order:catering"),
    },
  };

  // Order date and time header info
  const timeSlot = { datetime: orderTimeSlot, isAsap: false };
  const timezone = store?.timezone;
  let dateLabel = t("order:select_a_time");
  let timeLabel;
  let dateAndTimeLabel;
  if (timeSlot?.datetime) {
    // @ts-ignore
    const date = dayjs(timeSlot?.datetime).utc().tz(timezone);
    dateLabel = timeSlot.isAsap ? t("order:asap") : date.isToday() ? t("order:today") : date.format("MMMM D");
    timeLabel = date.format("h:mm a");
    dateAndTimeLabel = timeSlot.isAsap ? t("order:asap") : `${dateLabel} - ${timeLabel}`;
  }

  return { dateLabel, timeLabel, dateAndTimeLabel, ...labelsByTypeMap?.[type] };
};

export const getUniqueItemId = (item) => {
  const modifierOptions = item?.modifiers
    ?.map((modifier) => modifier?.options?.map((option) => `${option?.modifierOptionId}-${option?.quantity}`).join("-"))
    .join("-");

  const uniqueId = `${item?.product?.productId}-${modifierOptions}`;

  return uniqueId;
};

export const checkTimeSlot = async (order: OrderDetailsType, orderTimeSlot: string): Promise<string> => {
  if (!order?.source || !order?.store) {
    return;
  }

  // @ts-ignore
  const timeSlot = await preSelectWhen(order.source, order.storeId);
  if (!orderTimeSlot || dayjs(orderTimeSlot).isBefore(dayjs(timeSlot?.datetime))) {
    return timeSlot?.datetime;
  }
  return undefined;
};

export const preSelectWhen = async (source, storeId): Promise<TimeSlot> => {
  const type = source.type;
  if (type == SourceType.Catering || !storeId) {
    return null;
  }

  let asap = await getAsap(storeId, source?.type, source?.businessHours?.timezone);
  if (asap) {
    return { datetime: asap, isAsap: true };
  }

  // const availableDates = generateTimeSlots(source, store);
  // console.log("timeslot availableDates", availableDates);
  // const datetime = availableDates?.[0]?.[0];

  // if (!datetime) {
  //   return;
  // }

  // return { datetime, isAsap: false };
};

const getAsap = async (storeId, type, timezone) => {
  const availableHours = await client.mutate({
    mutation: SourceBusinessHoursDocument,
    variables: { storeId, type, selectedDate: dayjs()?.tz(timezone)?.format("YYYY-MM-DD") },
    context: { service: Service.pos },
  });
  const timeSlots = availableHours?.data?.public?.sourceForStore?.businessHoursForDay?.pickupTimeSlots;
  const result = timeSlots?.find((slot) => slot.isAvailable)?.startTimestamp;
  return result;
};

export const buildOrderUpsert = ({
  order,
  sourceId,
  sourceType,
  orderItems,
  timeSlot,
  deliveryAddress,
  paymentMethods,
  orderTip,
  pickupName,
  paymentIntent,
  customerId,
  vouchers = undefined,
  notes,
  rewardProducts = undefined,
  customerEmail,
  calculatePaymentMethods,
}: {
  order: Order;
  sourceId?: string;
  sourceType?: SourceType;
  orderItems?: OrderItemInput[] | any[];
  timeSlot: string;
  deliveryAddress?: AddressForOrder;
  paymentMethods?: AllPaymentMethodsInput;
  orderTip?: MoneyInput;
  pickupName?: string;
  paymentIntent?: OrderPaymentIntent;
  customerId?: string;
  vouchers?: OrderVoucherInput[];
  notes?: string;
  rewardProducts?: { rewardProductId: string }[];
  customerEmail?: string;
  calculatePaymentMethods?: boolean;
}) => {
  // get attributes from the order so that they're not erased from the new order if they don't get passed in
  const orderPaymentMethods = paymentMethods || order?.paymentMethods;
  const tip = orderTip || order?.tip;
  const items = orderItems || order?.items;
  const orderNotes = notes || order?.notes;
  const orderRewardProducts = typeof rewardProducts === "undefined" ? order?.rewardProducts : rewardProducts;
  const orderVouchers = typeof vouchers === "undefined" ? order?.vouchers : vouchers;
  const orderSourceType = sourceType || order?.source?.type;

  const orderUpsert: CaptureOrderUpsert = {
    fulfillment:
      orderSourceType === SourceType.Delivery
        ? {
            delivery: {
              name: deliveryAddress?.name,
              addressId: deliveryAddress?.addressId,
              deliveryWindowStart: timeSlot,
              deliveryWindowEnd: dayjs(timeSlot).add(15, "minutes").toISOString(),
            },
          }
        : orderSourceType === SourceType.Catering
          ? {
              terminal: {
                isCatering: true,
                name: pickupName || "",
                email: customerEmail || "",
                pickupAt: dayjs(timeSlot).utc().format(),
              },
            }
          : {
              pickup: {
                pickupAt: timeSlot,
                name: pickupName,
              },
            },
    origin: order?.origin || OrderOrigin.Web,
    sourceId: sourceId || order?.source?.sourceId,
    items:
      items?.map((item) => ({
        productId: item?.productId,
        modifiers: item?.modifiers?.map((modifier) => ({
          modifierId: modifier?.modifierId,
          // count up the options to get the quantity field
          options: Array.from(
            // @ts-ignore
            new Set(modifier?.options?.map((option) => option?.modifierOptionId || option?.optionId)),
          ).map((id) => ({
            modifierOptionId: id,
            quantity:
              // @ts-ignore
              modifier?.options?.find((option) => option?.modifierOptionId === id || option?.optionId === id)
                ?.quantity ||
              // @ts-ignore
              modifier?.options?.filter((option) => option?.modifierOptionId === id || option?.optionId === id)?.length,
          })),
        })),
        quantity: item?.quantity,
      })) || [],
    ...(orderPaymentMethods && {
      paymentMethods: {
        card: orderPaymentMethods?.card?.length
          ? [
              {
                amount: orderPaymentMethods?.card?.[0]?.amount,
                paymentIntentId: paymentIntent?.paymentIntentId,
                type: PaymentMethodType.Card,
                usingPhysicalCard: false,
              },
            ]
          : [],
        accounts: orderPaymentMethods?.accounts || [],
      },
    }),
    ...(order?.orderId && { orderId: order?.orderId }),
    ...(tip && { tip }),
    ...(customerId && { customerId }),
    ...(orderVouchers && { vouchers: orderVouchers?.map((voucher) => ({ voucherId: voucher?.voucherId })) || [] }),
    ...(orderRewardProducts && {
      rewardProducts: orderRewardProducts?.map((reward) => ({ rewardProductId: reward?.rewardProductId })) || [],
    }),
    ...(orderNotes && { notes: orderNotes }),
  };

  return orderUpsert;
};
