import LegacyImage, { ImageProps as LegacyImageProps } from "next/legacy/image";
import { cloudflareLoader } from "static/lib/images";

const isRelativeImage = (thing: any) => typeof thing !== "string" || thing.startsWith("/");
const applyLoader = (props) => ({
  ...props,
  ...(isRelativeImage(props.src) ? { unoptimized: true } : { loader: cloudflareLoader })
});

/**
 * @deprecated - use `components/v2/molecules/CloudFlareImage` instead
 */
export default function CloudFlareImage(props: Omit<LegacyImageProps, "loader">) {
  return <LegacyImage {...applyLoader(props)} />;
}
