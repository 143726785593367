import { fetchInfo } from "../component/account/requests";
import { fetchLocal, storeLocal } from "./util";

export const INTERCOM_APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID;
/*
{
    event: "trackEvent",
    eventLabel: "Order now"
    eventCategory: "Call-to-action buttons"
    eventAction: "Clicked"
    eventValue: 0 //integer

    generic button clicks
    {
        event: "trackEvent",
        eventLabel: "",
        eventCategory: "",
        eventAction: "",
        eventValue: 0
    }

    for more info: https://enhancedecommerce.appspot.com/
*/

export const track = (data) => {
  if (typeof window === "undefined") {
    return;
  }

  try {
    // @ts-ignore
    let dataLayer = window.dataLayer || [];
    dataLayer.push({ eventLabel: "", eventCategory: "", eventAction: "", eventValue: 0, ...data });
  } catch (err) {
    console.error("unexpected error in the dataLayer", err);
  }
};

export const trackAppDownload = (type = "IOS") => {
  if (typeof window === "undefined") {
    return;
  }

  try {
    // @ts-ignore
    let dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "trackEvent",
      eventLabel: type,
      eventCategory: "Download app",
      eventAction: "click",
      eventValue: type == "IOS" ? 1 : 2
    });
  } catch {}
};

export const trackUser = async () => {
  const account = await fetchInfo();

  track({
    event: "loggedUser",
    eventLabel: "User login",
    customerUserId: account?.userId || "",
    hasVisitedTheSiteBefore: fetchLocal("hasVisited") || false,
    numPastOrders: fetchLocal("customer")?.numOrdersFromWeb || 0,
    em: account?.email || "",
    ph: account?.phoneNumber || "",
    st: account?.addresses?.[0]?.state || "",
    ct: account?.addresses?.[0]?.city || "",
    name: account?.name || ""
  });
  storeLocal("hasVisited", true);
};
