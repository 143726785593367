import { TypedQueryDocumentNode, type DocumentNode } from "graphql";
import { client, Service } from "../../lib/apollo";
import { type GraphQLVariables } from "./apollo";

export const URL = process.env.NEXT_PUBLIC_POS_API;

/**
 * @deprecated - use `lib/apollo.ts#client` or `lib/apollo-hooks.ts` instead
 */
export const query = async (query: DocumentNode | TypedQueryDocumentNode, variables?: GraphQLVariables) => {
  const response = await client.query({
    query,
    ...(variables?.variables && { variables: { ...variables.variables } }),
    context: { service: Service.pos }
  });

  return response.data;
};

/**
 * @deprecated - use `lib/apollo.ts#client` or `lib/apollo-hooks.ts` instead
 */
export const mutate = async (query: DocumentNode | TypedQueryDocumentNode, variables?: GraphQLVariables) => {
  const response = await client.mutate({
    mutation: query,
    ...(variables?.variables && { variables: { ...variables.variables } }),
    context: { service: Service.pos }
  });

  return response.data;
};
