export const INITIAL_AMOUNT = 2500;
export const AMOUNT_INCREMENT = 500;
export const MINIMUM_AMOUNT = 500;
export const MAXIMUM_AMOUNT = 10000;
export const MAX_MESSAGE_LENGTH = 150;
export const IMMEDIATELY = "Immediately";
export const ORDER_STATUS = {
  processing: "PROCESSING",
  startCapturing: "START-CAPTURING",
  capturing: "CAPTURING",
  captured: "CAPTURED",
  failed: "FAILED",
  done: "DONE"
};
export const GIFTCARD_TYPE = "DIGITAL_GIFTCARDS";
export const IMAGESTORAGE = "ImageKeyStorage"; //just for UI
