import { useInterval } from "@/lib/hooks";
import CookieLoader from "../../atoms/CookieLoader/CookieLoader";
import IconCheckCircleFilled from "../../atoms/Icons/CheckCircleFilled";
import IconExclamationCircleFilled from "../../atoms/Icons/ExclamationCircleFilled";
import Text from "../../atoms/Text/Text";
import { OrderProcessingState, OrderProcessingStateType } from "../../contexts/OrderContext/helpers";
import { useOrderContextNew } from "../../contexts/OrderContextNew/OrderContextNew";
import Button from "../../molecules/Button/Button";

export default function CheckoutOrderProcessing({
  orderProcessingState,
  goBack,
  tryAgain
}: {
  orderProcessingState: OrderProcessingState;
  goBack: () => void;
  tryAgain: () => void;
}) {
  const message = messageForState(orderProcessingState);
  const { checkCaptureStatus, order, isCaptureOrderLoading } = useOrderContextNew();

  // Has potential to mark order as complete twice if race condition occurs. We need an abort controller to prevent this on the check capture status call
  useInterval(() => {
    if (orderProcessingState.type === OrderProcessingStateType.Capturing && order?.orderId && !isCaptureOrderLoading) {
      checkCaptureStatus();
    }
  }, 5000);

  return (
    <div className="fixed z-50 inset-0 bg-primary">
      <div className="flex flex-col items-center justify-between lg:justify-center space-y-12 w-full h-full p-8">
        <div />
        <div className="flex flex-col space-y-4 items-center">
          <ImageForState type={orderProcessingState.type} />

          <Text variant="title3" className="capitalize">
            {titleForState(orderProcessingState.type)}
          </Text>

          {message && (
            <Text variant="body2" className="text-center italic">
              {message}
            </Text>
          )}
        </div>

        <div className="flex flex-col space-y-4 w-full items-center">
          {canUpdateOrder(orderProcessingState.type) && (
            <Button variant="primary" className="w-full py-6" onClick={goBack}>
              {updateOrderButtonTextForState(orderProcessingState.type)}
            </Button>
          )}
          {canTryAgain(orderProcessingState.type) && (
            <Button variant="secondary" className="w-full py-6" onClick={tryAgain}>
              Try Again
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

const loadingForState = (type: OrderProcessingStateType): boolean => {
  switch (type) {
    case OrderProcessingStateType.Finalizing:
    case OrderProcessingStateType.Confirming:
    case OrderProcessingStateType.Capturing:
      return true;
    default:
      return false;
  }
};

const failedForState = (type: OrderProcessingStateType): boolean => {
  switch (type) {
    case OrderProcessingStateType.FailedFinalizing:
    case OrderProcessingStateType.FailedConfirming:
    case OrderProcessingStateType.FailedCapture:
      return true;
    default:
      return false;
  }
};

const titleForState = (type: OrderProcessingStateType) => {
  switch (type) {
    case OrderProcessingStateType.FailedFinalizing:
    case OrderProcessingStateType.FailedConfirming:
    case OrderProcessingStateType.FailedCapture:
      return "Declined";
    case OrderProcessingStateType.Captured:
      return "Approved!";
    default:
      return "Processing...";
  }
};

function ImageForState({ type }: { type: OrderProcessingStateType }) {
  return (
    <>
      {loadingForState(type) && (
        <div className="w-24 h-24 sm:w-32 sm:h-32 lg:w-36 lg:h-36">
          <CookieLoader />
        </div>
      )}
      {type === OrderProcessingStateType.Captured && (
        <IconCheckCircleFilled className="w-24 h-24 sm:w-32 sm:h-32 lg:w-36 lg:h-36" />
      )}
      {failedForState(type) && <IconExclamationCircleFilled className="w-24 h-24 sm:w-32 sm:h-32 lg:w-36 lg:h-36" />}
    </>
  );
}

function canUpdateOrder(type: OrderProcessingStateType) {
  switch (type) {
    case OrderProcessingStateType.FailedFinalizing:
    case OrderProcessingStateType.FailedConfirming:
    case OrderProcessingStateType.FailedCapture:
      return true;
    default:
      return false;
  }
}

function canTryAgain(type: OrderProcessingStateType) {
  switch (type) {
    case OrderProcessingStateType.FailedCapture:
      return true;
    default:
      return false;
  }
}

function messageForState(state: OrderProcessingState) {
  if (!failedForState(state.type)) {
    return null;
  }

  if (state.error) {
    return state.error;
  }

  if (state.validationErrors) {
    return state.validationErrors.map((error) => error.humanMessage).join("\n");
  }

  return "We were unable to process your order. Please try again.";
}

function updateOrderButtonTextForState(type: OrderProcessingStateType) {
  switch (type) {
    case OrderProcessingStateType.FailedConfirming:
      return "Change Payment Method";
    default:
      return "Update Order Details";
  }
}
