import Text, { TextVariant } from "@/components/v2/atoms/Text/Text";
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from "@headlessui/react";
import classNames from "classnames";
import { Fragment, ReactNode } from "react";
import IconTimesFilled from "../../atoms/Icons/TimesFilled";
const variants = {
  primary: {
    container: "bg-white",
    title: "p-5 bg-primary",
    modalContentClassName: "w-full h-full fixed inset-0",
    divider: false
  },
  primaryLight: {
    container: "bg-primary-light",
    title: "p-5 bg-primary-light",
    modalContentClassName: "w-full h-full fixed inset-0",
    divider: false
  },
  secondary: {
    container: "p-5 bg-secondary-light",
    title: "",
    modalContentClassName: "w-full h-full fixed inset-0",
    divider: true
  },
  secondaryNoDivider: {
    container: "p-5 bg-secondary-light",
    title: "",
    modalContentClassName: "w-full h-full fixed inset-0",
    divider: false
  },
  basic: {
    container: "bg-white",
    title: "",
    modalContentClassName: "w-full h-[80vh] fixed left-0 top-1/2 translate-y-[-50%]",
    divider: false
  }
} as const;

export type ModalVariant = keyof typeof variants;
export const allVariants = Object.keys(variants) as ModalVariant[];
export interface ModalProps {
  variant?: ModalVariant;
  isOpen?: boolean;
  onClose: () => void;
  children?: ReactNode;
  className?: string;
  wrapperClassName?: string;
  title?: ReactNode;
  dontCloseOnOutsideClick?: boolean;
  hideCloseButton?: boolean;
  titleType?: TextVariant;
}

const Modal = ({
  variant = "primary",
  isOpen = false,
  onClose,
  title,
  className,
  wrapperClassName,
  dontCloseOnOutsideClick = false,
  hideCloseButton = false,
  titleType,
  children
}: ModalProps) => {
  const { container, title: titleVariant, divider, modalContentClassName } = variants[variant];
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        onClose={dontCloseOnOutsideClick ? () => {} : onClose}
        className={classNames("relative z-50", wrapperClassName)}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-grey-20" aria-hidden="true" />
        </TransitionChild>
        <div className={classNames("flex items-center justify-center sm:p-4", modalContentClassName)}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-10"
            enterTo="opacity-100 translate-y-0"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-10"
          >
            <DialogPanel
              className={classNames(
                "w-full h-dvh min-w-80 md:min-w-[562px] md:min-h-[400px] max-w-2xl",
                "overflow-y-auto sm:rounded-xl sm:w-auto sm:h-auto sm:max-h-[90vh]",
                container,
                className
              )}
            >
              <div
                className={classNames(
                  "flex items-center gap-x-4",
                  titleVariant,
                  title ? "justify-between" : "justify-end"
                )}
              >
                {title && (
                  <DialogTitle>
                    <Text as={typeof title === "string" ? "p" : "div"} variant={titleType ?? "title1"}>
                      {title}
                    </Text>
                  </DialogTitle>
                )}
                {!hideCloseButton && (
                  <button onClick={onClose} className="fixed top-4 right-4">
                    <IconTimesFilled className="size-7" />
                  </button>
                )}
              </div>
              {divider && <div className="my-5 bg-grey-20 h-[1px] w-full" />}
              {children}
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
