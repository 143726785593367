import { fetchLocal, storeLocal } from "@/static/lib/util";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { GiftCardOrder } from "./helpers";

const LOCAL_KEY = "crumblGiftCardOrder";
const fetchLocalOrder = (): GiftCardOrder | null => fetchLocal(LOCAL_KEY) || {};
const storeLocalOrder = (order: GiftCardOrder) => storeLocal(LOCAL_KEY, order);

export type GiftCardOrderContextType = {
  order: GiftCardOrder;
  loadingOrder: boolean;
  updateOrder: (updatedOrder: Partial<GiftCardOrder>) => GiftCardOrder;
  clearOrder: () => void;
  addManualRecipient: () => void;
  deleteRecipientbyIndex: (index: number) => void;
};

export const GiftCardOrderContext = React.createContext<GiftCardOrderContextType>({
  order: null,
  loadingOrder: true,
  updateOrder: (updatedOrder: Partial<GiftCardOrder>) => updatedOrder,
  clearOrder: null,
  addManualRecipient: () => null,
  deleteRecipientbyIndex: () => null
});

export function GiftCardOrderContextProvider({ children }) {
  const [order, setOrder] = useState(fetchLocalOrder());
  const [loadingOrder, setLoadingOrder] = useState(true);

  // SSR gives us hydration errors if we try to load initial values from localStorage, so we load them here in a useEffect instead
  useEffect(() => {
    if (typeof window !== "undefined") {
      const order = fetchLocalOrder();
      setOrder(order);
      setLoadingOrder(false);
    }
  }, []);

  //reset everything except the product (so it doesn't trigger pushing to /gifting
  const clearOrder = () => {
    const newOrder = { product: order?.product };
    setOrder(newOrder);
    storeLocalOrder(newOrder);
  };

  const updateOrder = useCallback(
    (updates: Partial<GiftCardOrder>) => {
      const updatedOrder = { ...order, ...updates };
      setOrder(updatedOrder);
      storeLocalOrder(updatedOrder);

      return updatedOrder;
    },
    [order, setOrder]
  );

  const addManualRecipient = () => {
    const updatedRecipients = [
      ...(order?.recipients || []),
      {
        name: order?.newRecipientName,
        phoneOrEmail: order?.newRecipientPhoneOrEmail
      }
    ];
    updateOrder({
      recipients: updatedRecipients,
      newRecipientName: "",
      newRecipientPhoneOrEmail: ""
    });
  };

  const deleteRecipientbyIndex = (index: number) => {
    const updatedRecipients = (order?.recipients || []).filter((_r, i) => i !== index);
    updateOrder({
      recipients: updatedRecipients
    });
  };

  const value: GiftCardOrderContextType = {
    order,
    loadingOrder,
    updateOrder,
    clearOrder,
    addManualRecipient,
    deleteRecipientbyIndex
  };

  return <GiftCardOrderContext.Provider value={value}>{children}</GiftCardOrderContext.Provider>;
}

export const useGiftCardOrderContext = () => useContext(GiftCardOrderContext);
