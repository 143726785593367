import { useAppContext } from "components/AppContext";
import { motion } from "framer-motion";

export default function ModalMotion({
  children,
  isVertical = false,
  reverse = false,
  className = "",
  contentKey = ""
}) {
  const { isNewMobileView } = useAppContext();
  if (!isNewMobileView) {
    return children;
  }

  const props = isVertical
    ? {
        initial: { y: 50, opacity: 0 },
        animate: { y: 0, opacity: 1 },
        exit: { y: 50, opacity: 0 }
      }
    : {
        initial: { x: reverse ? -50 : 50, opacity: 0 },
        animate: { x: 0, opacity: 1 }
        //exit: { x: reverse ? 50 : -50, opacity: 0 }
      };

  return (
    <motion.div {...props} className={className} key={contentKey} transition={{ type: "tween", duration: 0.15 }}>
      {children}
    </motion.div>
  );
}
