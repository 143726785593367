import { PublicStoreInfo } from "@/generated/requests/backend";
import {
  Account,
  AllPaymentMethodsInput,
  CaptureOrderUpsert,
  Currency,
  FormattedOrderReceipt,
  GenericSourceForStoreQuery,
  Money,
  Order,
  OrderError,
  OrderFulfillmentInput,
  OrderItemInput,
  OrderOrigin,
  OrderRewardProduct,
  OrderTotals,
  OrderUpsell,
  ProductModifierOption,
  ProductModifierSpecialSubtype,
  Source,
  SourceBusinessHoursDocument,
  SourceProductAutomaticDiscounts,
  SourceType,
  Voucher
} from "@/generated/requests/pos";
import { Address } from "@/generated/requests/services";
import { UpsertOrderResponse } from "@/static/component/order/requests";
import { trackFBAddFlavorToCart, trackFBAddProductToCart } from "@/static/lib/facebook";
import { track } from "@/static/lib/tracking";
import { centsToDecimal, formatMoney, getDiscountRate } from "@/static/lib/util";
import { DeepPartial } from "@apollo/client/utilities";
import dayjs, { Dayjs } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import utc from "dayjs/plugin/utc";
import { Service, client } from "lib/apollo";
import { CustomerType } from "../CustomerContext/CustomerContext";

dayjs.extend(utc);
dayjs.extend(isBetween);

const loggerEnabled = typeof window !== "undefined" && !!window.localStorage.getItem("debugOrder");
const noop = (...args) => void 0;
/**
 * Logger controlled by localStorage flag. Must set `debugOrder` to any value to
 * see log messages.
 */
export const orderLogger = new Proxy(console, {
  get(target, name) {
    if (loggerEnabled) {
      return target[name];
    }
    return noop;
  }
});

export const SUPPORTED_ORDER_TYPES: SourceType[] = [
  SourceType.Delivery,
  SourceType.CarryOut,
  SourceType.Pickup,
  SourceType.Catering,
  SourceType.Shipping
];

export function getSourceTypeFromUrlOrderType(urlOrderType: string) {
  const map = {
    delivery: SourceType.Delivery,
    carry_out: SourceType.CarryOut,
    pickup: SourceType.Pickup,
    catering: SourceType.Catering
  };
  return map[urlOrderType];
}

export type OrderDetailsSource = GenericSourceForStoreQuery["public"]["sourceForStore"];
export type OrderDetailsStore = PublicStoreInfo;
export type OrderDetailsAddress = Address & { addressValidated?: boolean };

export interface ClientOrderItemProductModifierOption {
  modifierOptionId: string;
  quantity: number;
  price: number;
  image?: string;
  name?: string;
}
export interface ClientOrderItemProductModifier {
  modifierId: string;
  options: ClientOrderItemProductModifierOption[];
  specialSubtypes?: ProductModifierSpecialSubtype[];
}
export interface ClientOrderItemProduct {
  productId: string;
  quantity: number;
  modifiers: ClientOrderItemProductModifier[];
}
export interface ClientOrderItemMetaOptionAvailability {
  optionId: string;
  cookieId: string;
  startDate: string;
  endDate: string;
}
export interface ClientOrderItemMeta {
  title: string;
  category: string;
  automaticDiscounts: SourceProductAutomaticDiscounts;
  lineItems: string[];
  lineItemsInfo: LineItemsInfoType[];
  images: string[];
  featuredPartners: string[];
  flavorIds: string[];
  optionAvailability: ClientOrderItemMetaOptionAvailability[];
  productQuantityByType: { [key: string]: number };
  ts: number;
}
export interface ClientOrderItemPrice {
  originalPrice: number;
  discountedPrice: number;
}
export interface ClientOrderItem {
  product: ClientOrderItemProduct;
  meta: ClientOrderItemMeta;
  price: ClientOrderItemPrice;
}

export interface LineItemsInfoType {
  quantity: number;
  name: string;
  message?: string;
  price?: string;
  calories?: string;
  image?: string;
  upcharge?: string;
}
export interface TimeSlot {
  datetime: string;
  isAsap?: boolean; // delivery only
}
export type MoneyType = Omit<Money, "__typename">;

/**
 * Order details needed to generate a `CaptureOrderUpsert` object.
 */
export interface OrderDetailsType {
  orderId?: string;
  receiptId?: string;
  source?: OrderDetailsSource;
  store?: OrderDetailsStore;
  storeSources?: DeepPartial<Source[]>;
  address?: OrderDetailsAddress;
  customerId?: string;
  shipping?: any;
  timeSlot?: TimeSlot;
  name?: string;
  note?: string;
  email?: string;
  items?: ClientOrderItem[];
  totals?: OrderTotals;
  upsell?: OrderUpsell;
  paymentMethods?: AllPaymentMethodsInput;
  crumblCashAccount?: DeepPartial<Account>;
  rewardProducts?: OrderRewardProduct[];
  vouchers?: Voucher[];
  isUpdating?: boolean;
  lastUpdateEndedAt?: Date;
  formattedReceipt?: FormattedOrderReceipt;
  recentlyRemovedItem?: ClientOrderItem;
  tipSelection?: OrderTipSelection;
  processingState?: OrderProcessingState;
  paymentIntent?: UpsertOrderResponse["paymentIntent"];
  validationErrors?: OrderError[];
}

export type OrderProcessingState = {
  type: OrderProcessingStateType;
  error?: string;
  validationErrors?: OrderError[];
};

export enum OrderProcessingStateType {
  NotProcessing = "NOT_PROCESSING",
  Finalizing = "FINALIZING",
  FailedFinalizing = "FAILED_FINALIZING",
  Finalized = "FINALIZED",
  Confirming = "CONFIRMING",
  FailedConfirming = "FAILED_CONFIRMING",
  Confirmed = "CONFIRMED",
  Capturing = "CAPTURING",
  FailedCapture = "FAILED_CAPTURE",
  Captured = "CAPTURED"
}

export type OrderTipSelection = {
  custom?: boolean;
  percent?: number;
  amount?: number;
};

const PICKUP_INTERVAL = 15;
const DELIVERY_INTERVAL = 30;
const DEFAULT_TIMEZONE = "America/Chicago";
const GIFT_WRAPPING = "GIFTWRAPPING";
const MYSTERY_COOKIE_ID = "37ab4760-7e09-11ec-b436-b90f8a5683db:Cookie";
const MYSTERY_COOKIE_IMAGE = "/images/mystery-cookie.gif";
const COOKIE_FLAVOR = "COOKIEFLAVOR";
const ICECREAM_FLAVOR = "ICECREAMFLAVOR";
const MINI_COOKIE_FLAVOR = "MINI_COOKIE_FLAVOR";
const PREFILL_COOKIE_FLAVOR = "PREFILL_COOKIE_FLAVOR";
const PREFILL_WEEKLY_MENU = "PREFILL_WEEKLY_MENU";
export const GIFTCARD = "giftcard";
export const VOUCHER = "voucher";

/**
 * Build OrderFulfillmentInput object
 *
 * @param order the current order object
 * @returns order fulfillment input
 */
const buildOrderFulfillment = (order: OrderDetailsType): OrderFulfillmentInput => {
  switch (order?.source?.type) {
    case SourceType.Delivery:
      return {
        delivery: {
          name: order.address?.name,
          addressId: order.address?.addressId,
          deliveryWindowStart: dayjs(order.timeSlot?.datetime).utc().format(),
          deliveryWindowEnd: dayjs(order.timeSlot?.datetime).utc().add(DELIVERY_INTERVAL, "minutes").format(),
          deliverAsap: order.timeSlot?.isAsap
        }
      };
    case SourceType.CarryOut:
    case SourceType.Pickup:
      return {
        pickup: {
          name: order.name || "",
          pickupAt: dayjs(order.timeSlot?.datetime).utc().format()
          // TODO: add `customerArrivedDescription` for curbside orders?
        }
      };
    case SourceType.Catering:
      return {
        terminal: {
          isCatering: true,
          name: order.name || "",
          email: order.email || "",
          pickupAt: dayjs(order.timeSlot?.datetime).utc().format()
        }
      };
    case SourceType.Shipping:
      return {
        shipping: {
          name: order.address?.name,
          addressId: order.address?.addressId,
          rateId: order.shipping?.rate?.shippingRateId
        }
      };
    default:
      return {};
  }
};

/**
 * Build OrderItemInput array
 *
 * @param order the current order object
 * @returns order item input array
 */
const buildOrderItems = (order: OrderDetailsType): OrderItemInput[] => {
  return (
    order?.items?.map(({ product }) => ({
      ...product,
      modifiers: product?.modifiers?.map((modifier) => ({
        modifierId: modifier.modifierId,
        options: modifier?.options?.map((option) => ({
          modifierOptionId: option.modifierOptionId,
          quantity: option.quantity
        }))
      }))
    })) || []
  );
};

/**
 * Build `CaptureOrderUpsert` object
 *
 * The final upsert is a little challenging to work with while building out an
 * order object (see `buildOrderFulfillment`), so we use this function to build
 * it out using a separate order details object for tracking the information.
 *
 * @param order the current order object
 * @returns order upsert object
 */
export const buildOrderUpsert = (order: OrderDetailsType, customer: CustomerType = undefined): CaptureOrderUpsert => {
  const items = buildOrderItems(order);
  const fulfillment = buildOrderFulfillment(order);
  const vouchers = order?.totals?.vouchers?.map((voucher) => ({ voucherId: voucher.voucherId }));
  const rewardProducts = order?.rewardProducts?.map(({ rewardProductId }) => ({ rewardProductId }));
  const defaultTip = { currency: getCurrency(order), amount: 200 };
  const tip = order?.totals?.tip?.currency ? order.totals.tip : defaultTip;

  // if the crumblCashAccount is being used, make sure the amount is a valid one
  const paymentMethods = {
    ...order?.paymentMethods,
    accounts: (order?.paymentMethods?.accounts || []).map((acct) =>
      acct.accountId === order?.crumblCashAccount?.accountId
        ? {
            ...acct,
            amount: {
              ...acct.amount,
              amount: Math.min(order?.crumblCashAccount?.balance || 0, order?.totals?.total?.amount || 0)
            }
          }
        : acct
    )
  };

  return {
    origin: OrderOrigin.Web,
    // TODO: include a git commit hash so we can track down errors?
    // originVersion: "",
    orderId: order?.orderId,
    sourceId: order?.source?.sourceId,
    customerId: customer?.userId || order?.customerId,
    notes: order?.note,
    items,
    fulfillment,
    tip,
    vouchers,
    paymentMethods,
    rewardProducts: rewardProducts
  };
};

/**
 * Migrate products to a new source
 *
 * Whenever we change the source, we have to migrate products because pricing
 * and availability is source-specific.
 *
 * @param source the new source for the migration
 * @param products the products previously added to the order
 * @returns products available with the new source
 */
export const migrateSourceProducts = (source: OrderDetailsSource, products: any[]): any[] => {
  if (!source) {
    return products;
  }

  const result = products
    ?.filter(({ product }) => {
      // validate product, modifiers, and options are available on the new
      // source
      const productId = product?.productId;
      const sourceProduct = source?.products?.find((p) => p?.product?.productId === productId);

      if (!sourceProduct) {
        return false;
      }

      const wrongModifiers = product?.modifiers?.find((modifier) => {
        const sourceModifier = sourceProduct?.product?.modifiers?.find((m) => m.modifierId === modifier.modifierId);

        if (!sourceModifier) {
          return true;
        }

        const sourceModifierOptionIds = sourceModifier?.options?.map(({ optionId }) => optionId);
        const wrongOption = modifier?.options?.find(
          ({ modifierOptionId }) => !sourceModifierOptionIds?.includes(modifierOptionId)
        );
        return !!wrongOption;
      });

      return !wrongModifiers;
    })
    ?.map((p) => {
      // update pricing since prices are source-specific
      const { product, price } = p;
      const productId = product?.productId;
      const sourceProduct = source.products?.find((p) => p?.product?.productId === productId);
      return { ...p, price: sourceProduct?.price || price };
    });
  return result;
};

/**
 * Generate available time slots for a given source and store
 *
 * @param source the source object for the order
 * @param store the store object for the order
 * @param availableCateringDates if the source type is Catering, pass in the available catering dates as well so this function can generate the timeslots in the appropriate format for those dates
 * @returns time slots
 */
export const generateTimeSlots = (
  source,
  store,
  availableCateringDates?: CateringDayInfoForTimeslotGeneration[]
): string[][] => {
  if (!source || (source.type === SourceType.Catering && !availableCateringDates)) {
    return [];
  }

  if (source.type === SourceType.Catering) {
    return availableCateringDates.map((cateringDay) => {
      const timesForDay = getAvailableTimesForCateringDay(cateringDay, source);
      return timesForDay.map((date) => dayjs(date).format());
    });
  }

  const isDelivery = source.type === SourceType.Delivery;
  const interval = isDelivery ? DELIVERY_INTERVAL : PICKUP_INTERVAL;
  const timezone = source.businessHours?.timezone || store?.storeHours?.timezone || store?.timezone || DEFAULT_TIMEZONE;
  const now = dayjs().tz(timezone);
  const dateFormat = "YYYY-MM-DD";

  return source?.businessHours?.openings
    ?.map(({ open, close }) => {
      let [start, end] = [open, close].map((time) => dayjs(time).tz(timezone));

      // delivery window closes 30 minutes before the store closes
      if (isDelivery) {
        end = end.subtract(30, "minutes");
      }

      let times = [];
      let pointer = start.clone();

      while (pointer.isBefore(end)) {
        // only allow times in the future (+3 minutes)
        if (pointer.diff(now, "minutes") > 3) {
          times.push(pointer.clone().format());
        }

        pointer = pointer.add(interval, "minutes");
      }

      const closures = source?.businessHours?.sourceClosures
        ?.filter(
          ({ eventDate }) => start.format(dateFormat) == dayjs(eventDate, dateFormat).tz(timezone).format(dateFormat)
        )
        ?.map(({ hours }) => [
          dayjs(`${hours.startingDate} ${hours.startingHour}:00:00`, "YYYY-MM-DD HH:mm:ss").tz(timezone),
          dayjs(`${hours.endingDate} ${hours.endHour}:00:00`, "YYYY-MM-DD HH:mm:ss").tz(timezone)
        ]);
      if (!closures?.length) {
        return times;
      }
      return times.filter((time) => {
        return !closures?.find(([start, end]) => dayjs(time).isBetween(start, end, undefined, "[)"));
      });
    })
    ?.filter((times) => times.length);
};

/**
 * Find the next available time slot for the given order
 *
 * @param source the source object for the order
 * @param store the store object for the order
 * @param address (optional) the address object for the order
 * @returns a pre-selected time slot, if available
 */
export const preSelectWhen = async (source, store, address): Promise<TimeSlot> => {
  const type = source.type;
  const storeId = store?.storeId;
  if (type == SourceType.Catering || !storeId) {
    return null;
  }

  let asap = await getAsap(source, store, address);
  if (asap) {
    return { datetime: asap, isAsap: true };
  }

  const availableDates = generateTimeSlots(source, store);
  const datetime = availableDates?.[0]?.[0];

  if (!datetime) {
    return;
  }

  return { datetime, isAsap: false };
};

/**
 * Find the next available time slot available that is ASAP (delivery only)
 *
 * @param source the source object for the order
 * @param store the store object for the order
 * @param address the address object for the order
 * @returns an ASAP delivery time slot, if available
 */
export const getAsap = async (source, store, address): Promise<string> => {
  if (source.type !== SourceType.Delivery) {
    return null;
  }

  const storeId = store?.storeId;
  const addressId = address?.addressId;

  if (!storeId || !addressId) {
    return null;
  }

  const { data } = await client.query({
    query: SourceBusinessHoursDocument,
    context: { service: Service.pos },
    variables: {
      storeId,
      type: source?.type,
      selectedDate: dayjs().format("YYYY-MM-DD")
    }
  });
  const timeSlots = data?.public?.sourceForStore?.businessHoursForDay?.pickupTimeSlots;
  const result = timeSlots?.find((slot) => slot.isAvailable)?.startTimestamp;
  return result;
};

/**
 * Get the timezone for the given order
 *
 * @param order the current order object
 * @returns  a dayjs timezone string
 */
export const getOrderTimezone = (order: Order) => {
  return order?.source?.businessHours?.timezone || DEFAULT_TIMEZONE;
};

/**
 * Get the currency for the given order
 *
 * @param order the current order object
 * @returns a currency code
 */
export const getCurrency = (order: OrderDetailsType): Currency => {
  return order?.totals?.total?.currency || order?.store?.currency || Currency.Usd;
};

/**
 * Check the timeSlot for the given order. Returns a new, updated timeSlot for
 * the order or nothing at all if no change is required.
 *
 * @param order the current order object
 * @returns a new, updated timeSlot for the order
 */
export const checkTimeSlot = async (order: OrderDetailsType): Promise<TimeSlot> => {
  if (!order?.source || !order?.store) {
    return;
  }

  const timeSlot = await preSelectWhen(order.source, order.store, order.address);
  if (!order.timeSlot?.datetime || dayjs(order.timeSlot?.datetime).isBefore(dayjs(timeSlot?.datetime))) {
    return timeSlot;
  }
};

/**
 * Get a formatted ClientOrderItem that can be added to the user's cart
 *
 * @param selectedProduct the selected source product
 * @param modifiers the product modifiers
 * @param quantity the quantity
 * @returns a ClientOrderItem
 */
export const getProductForCart = (selectedProduct, modifiers, quantity, order, locale): ClientOrderItem => {
  const { product, price, automaticDiscounts } = selectedProduct;
  const currency = getCurrency(order);

  let images = [];
  let lineItems: { [optionId: string]: LineItemsInfoType } = {};
  let modifiersInput = [];
  let flavorIds = [];
  let featuredPartners = [];
  let optionAvailability = [];
  let productQuantityByType = {};
  let priceWithOptions = price;

  if (modifiers?.length) {
    modifiers?.forEach(({ modifierId, options, specialType, specialSubtypes }) => {
      let optionsInput = [];
      let productModifier = product?.modifiers?.find((m) => m?.modifierId == modifierId);
      options?.forEach((option) => {
        const { name, image, optionId, price, metadata, quantity = 0, prefillQuantity = 0, upcharge } = option;
        const actualQuantity = quantity || prefillQuantity || 1;
        optionsInput.push({ modifierOptionId: optionId, quantity: actualQuantity, price: price });
        !specialSubtypes?.includes("GIFTWRAPPING") && (priceWithOptions += actualQuantity * price);

        // If it's a cookie, see if we need to stash of days the cookie should be available
        if (metadata?.cookieId) {
          if (!productQuantityByType[specialType]) {
            productQuantityByType[specialType] = 0;
          }
          productQuantityByType[specialType] += actualQuantity;

          const modifierOptions =
            productModifier?.options?.filter(
              (o) => o?.metadata?.cookieId == metadata?.cookieId && o?.startDate && o?.endDate
            ) || [];

          // Are there options with certain availability?
          if (modifierOptions.length) {
            const earliestStartDate = modifierOptions.reduce((earliest, option) => {
              if (!earliest || dayjs(option.startDate).isBefore(earliest)) {
                return option.startDate;
              }
              return earliest;
            }, null);

            const furthestEndDate = modifierOptions.reduce((furthest, option) => {
              if (!furthest || dayjs(option.endDate).isAfter(furthest)) {
                return option.endDate;
              }
              return furthest;
            }, null);

            if (earliestStartDate && furthestEndDate) {
              optionAvailability.push({
                optionId,
                cookieId: metadata?.cookieId,
                startDate: earliestStartDate,
                endDate: furthestEndDate
              });
            }
          }
        }
        if (specialType === GIFT_WRAPPING || specialSubtypes?.includes(ProductModifierSpecialSubtype.Giftwrapping)) {
          const giftWrapPrice = price;
          lineItems[optionId] = {
            quantity: 0,
            name: `Gift Wrapping`,
            message: name,
            price: formatMoney(giftWrapPrice, locale, currency)
          };
        } else if (
          specialSubtypes?.includes(ProductModifierSpecialSubtype.Catering) &&
          !specialSubtypes?.includes(ProductModifierSpecialSubtype.Packaging)
        ) {
          lineItems[optionId] = {
            quantity: (lineItems?.[optionId]?.quantity || 0) + 1,
            name,
            image,
            calories: metadata?.calorieInformation?.total,
            upcharge: upcharge
          };
        } else if (specialSubtypes?.includes(ProductModifierSpecialSubtype.Cookie)) {
          images.push(metadata?.cookieId == MYSTERY_COOKIE_ID ? MYSTERY_COOKIE_IMAGE : image);
          featuredPartners.push(metadata?.featuredPartnerLogo);
          lineItems[optionId] = {
            quantity: (lineItems?.[optionId]?.quantity || 0) + 1,
            name,
            calories: metadata?.calorieInformation?.total,
            image: image,
            upcharge: upcharge
          };
        }
        if (
          [COOKIE_FLAVOR, MINI_COOKIE_FLAVOR, PREFILL_COOKIE_FLAVOR, PREFILL_WEEKLY_MENU, ICECREAM_FLAVOR].includes(
            specialType
          ) &&
          !specialSubtypes?.includes(ProductModifierSpecialSubtype.Catering)
        ) {
          flavorIds.push(metadata.cookieId || metadata.iceCreamId);
        }
      });

      modifiersInput.push({
        modifierId,
        options: optionsInput
      });
    });
  }

  if (!images?.length) {
    images = [product?.image];
  }

  // If there is packaging to be selected, then default to the cheapest one
  // and calculate the quantity of packages.
  const packagingModifier = modifiers?.find((m) =>
    (m?.specialSubtypes || "")?.includes(ProductModifierSpecialSubtype?.Packaging)
  );

  if (packagingModifier) {
    // only the packaging option that the user selected is included. so it will always be options?.[0]
    const packagingOption = packagingModifier?.options?.[0];
    const cookiesModifier = modifiers?.find((m) => m?.modifierId !== packagingModifier?.modifierId);
    const quantity = cookiesModifier?.options?.reduce((acc, o) => acc + (o?.quantity || 1), 0);
    const packagingQuantity = Math.ceil(quantity / (packagingOption?.metadata?.catering?.packagingCookieCount || 1));

    modifiersInput.push({
      ...packagingModifier,
      modifierId: packagingModifier?.modifierId,
      options: [
        {
          modifierOptionId: packagingOption.optionId,
          quantity: packagingQuantity,
          image: packagingOption?.image,
          price: packagingOption?.price,
          name: packagingOption?.name
        }
      ]
    });
  }

  // If there's discount rate, use discount rate to calculate the price
  // If not, check if there's a price with discounts, if so, use that price + the price of the modifier options
  // If non, discountedPrice is null
  let discountedPrice;
  if (getDiscountRate(automaticDiscounts?.discounts) > 0) {
    discountedPrice = priceWithOptions * (1 - getDiscountRate(automaticDiscounts?.discounts));
  } else if (automaticDiscounts?.priceWithDiscounts) {
    discountedPrice =
      automaticDiscounts?.priceWithDiscounts +
      modifiersInput?.reduce((acc, m) => {
        return acc + m?.options?.reduce((acc, o) => acc + o?.price, 0);
      }, 0);
  } else {
    discountedPrice = null;
  }
  const item: ClientOrderItem = {
    product: {
      productId: product?.productId,
      quantity,
      modifiers: modifiersInput
    },

    // When adding new items to the cart, we need to calculate the price of the item
    // by using the discount rate from the automatic discounts, not just the discountedPrice
    // because we need to account for the upCharge items that are added to the cart
    price: {
      originalPrice: priceWithOptions,
      discountedPrice: discountedPrice
    },

    meta: {
      title: product?.name,
      images,
      featuredPartners,
      lineItems: Object.values(lineItems).map((l: any) =>
        l?.upcharge ? `${l?.quantity} x ${l?.name} * (+${formatMoney(l?.upcharge)} ea.)` : `${l?.quantity} x ${l?.name}`
      ),

      lineItemsInfo: [...Object.values(lineItems)],
      automaticDiscounts,
      category: product?.productCategory?.name || "",
      flavorIds,
      optionAvailability,
      productQuantityByType,
      // this is strictly for the client side to be used as the component
      // `key` attribute to force re-rendering of the component when products
      // are removed from the cart (otherwise, the component will not re-render)
      ts: Date.now()
    }
  };

  // Track AddToCart
  if (flavorIds.length) {
    trackFBAddFlavorToCart(centsToDecimal(automaticDiscounts?.priceWithDiscounts || price), currency, flavorIds);
  } else {
    trackFBAddProductToCart(centsToDecimal(automaticDiscounts?.priceWithDiscounts || price), currency, [
      product?.productId
    ]);
  }

  track({
    event: "addToCart",
    ecommerce: {
      currencyCode: currency,
      add: {
        products: [
          {
            name: product?.name,
            id: product?.productId,
            price: price / 100,
            category: product?.productCategory?.name || "",
            variant: item.meta.lineItems.join(", "),
            quantity
          }
        ]
      }
    }
  });

  return item;
};

//Gift Wrapping Helpers
const getSourceProductFromItem = (order: OrderDetailsType, itemToUpdate: ClientOrderItem) =>
  order?.source?.products?.find((p) => p.product?.productId === itemToUpdate.product.productId)?.product;
export const getProductGiftWrapModifierWithAllOptions = (sourceProduct) =>
  sourceProduct?.modifiers?.find((modifier) =>
    modifier.specialSubtypes.includes(ProductModifierSpecialSubtype.Giftwrapping)
  );
const getModifierListWithRemovedGiftWrapModifier = (order: OrderDetailsType, itemToUpdate: ClientOrderItem) => {
  const sourceProduct = getSourceProductFromItem(order, itemToUpdate);
  const productGiftWrapModifierWithAllOptions = getProductGiftWrapModifierWithAllOptions(sourceProduct);
  return itemToUpdate?.product?.modifiers?.filter(
    (modifier) => modifier.modifierId !== productGiftWrapModifierWithAllOptions?.modifierId
  );
};

const getModifierListWithUpdatedGiftWrapModifier = (
  order: OrderDetailsType,
  itemToUpdate: ClientOrderItem,
  newModifierOption: ProductModifierOption
): ClientOrderItemProductModifier[] => {
  const sourceProduct = getSourceProductFromItem(order, itemToUpdate);
  const productGiftWrapModifierWithAllOptions = getProductGiftWrapModifierWithAllOptions(sourceProduct);
  const updatedModifierList = itemToUpdate?.product?.modifiers?.map((modifier) =>
    modifier.modifierId === productGiftWrapModifierWithAllOptions?.modifierId
      ? {
          ...modifier,
          options: [
            {
              modifierOptionId: newModifierOption.optionId,
              quantity: 1,
              price: newModifierOption.price,
              name: newModifierOption.name
            }
          ]
        }
      : modifier
  );
  return updatedModifierList;
};

export const getItemWithUpdatedGiftWrapModifierAndLineItems = (
  order: OrderDetailsType,
  itemToUpdate: ClientOrderItem,
  newModifierOption: ProductModifierOption
): ClientOrderItem => {
  const newModifierListWithUpdatedGiftWrapModifier = getModifierListWithUpdatedGiftWrapModifier(
    order,
    itemToUpdate,
    newModifierOption
  );
  const newLineItemsInfo = itemToUpdate.meta.lineItemsInfo.map((info) =>
    info.name === "Gift Wrapping" ? { ...info, message: newModifierOption.name } : info
  );
  return {
    ...itemToUpdate,
    product: {
      ...itemToUpdate.product,
      modifiers: newModifierListWithUpdatedGiftWrapModifier
    },
    meta: {
      ...itemToUpdate.meta,
      lineItemsInfo: newLineItemsInfo
    }
  };
};

export const getItemWithRemovedGiftWrapModifierAndLineItems = (
  order: OrderDetailsType,
  itemToUpdate: ClientOrderItem
): ClientOrderItem => {
  const newModifierListWithoutGiftWrapModifier = getModifierListWithRemovedGiftWrapModifier(order, itemToUpdate);
  return {
    ...itemToUpdate,
    product: {
      ...itemToUpdate.product,
      modifiers: newModifierListWithoutGiftWrapModifier
    },
    meta: {
      ...itemToUpdate.meta,
      lineItemsInfo: itemToUpdate.meta.lineItemsInfo.filter((info) => info.name !== "Gift Wrapping"),
      lineItems: itemToUpdate.meta.lineItems.filter((lineItem) => !lineItem.includes("Gift Wrapping"))
    }
  };
};

export const getProductGiftWrapModifierOptionWithAllDataForItem = (sourceProduct, item: ClientOrderItem) => {
  const productGiftWrapModifierWithAllOptions = getProductGiftWrapModifierWithAllOptions(sourceProduct);
  const itemGiftWrapModifier = item?.product?.modifiers?.find(
    (modifier) => modifier.modifierId == productGiftWrapModifierWithAllOptions?.modifierId
  );
  return productGiftWrapModifierWithAllOptions.options.find(
    (option) => option.optionId == itemGiftWrapModifier?.options[0].modifierOptionId
  );
};

export const getAvailableTimesForCateringDay = (
  { startDate, endDate }: CateringDayInfoForTimeslotGeneration,
  source
) => {
  const closures = source?.businessHours?.sourceClosures || [];
  const timezone = source?.businessHours?.timezone || "America/Boise";
  const dateFormat = "YYYY-MM-DD";

  const start = dayjs(startDate).tz(timezone);
  const end = dayjs(endDate).tz(timezone);
  let times = [];
  let pointer = start.clone();
  while (pointer.isSameOrBefore(end)) {
    times.push(pointer.clone());

    pointer = pointer.add(30, "minutes");
  }

  const dayOpenings = closures
    ?.filter(({ eventDate }) => start.format(dateFormat) == dayjs(eventDate, dateFormat).format(dateFormat))
    ?.map(({ hours }) => [
      dayjs(`${hours.startingDate} ${hours.startingHour}:00:00`, "YYYY-MM-DD HH:mm:ss").tz(timezone),
      dayjs(`${hours.endingDate} ${hours.endHour}:00:00`, "YYYY-MM-DD HH:mm:ss").tz(timezone)
    ]);

  if (!dayOpenings?.length) {
    return times;
  }

  return times.filter((time) => !dayOpenings?.find(([start, end]) => dayjs(time).isBetween(start, end)));
};

type CateringDayInfoForTimeslotGeneration = {
  startDate: string | Dayjs;
  endDate: string | Dayjs;
};
