import { Portal } from "@headlessui/react";
import { AnimatePresence } from "framer-motion";
import ModalMotion from "static/component/order/mobile/ModalMotion";

//top-[48px] h-[calc(100vh_-_48px)]  max-h-[calc(100vh_-_48px)]
const HEADER_HEIGHT = "48px";

export default function FullScreenMobileModal({ children, visible = false }) {
  return (
    <Portal>
      <AnimatePresence mode="wait" initial={visible}>
        {visible && (
          <ModalMotion
            isVertical={true}
            className={`fixed z-[2] top-[${HEADER_HEIGHT}] left-0 right-0 bottom-0 overflow-y-auto overflow-x-hidden bg-white`}
          >
            <div className="fullscreen-mobile-modal-container h-[calc(100vh_-_48px)] flex justify-center">
              <div className="fullscreen-mobile-modal-contents w-full max-h-[calc(100vh_-_48px)] overflow-y-auto">
                {children}
              </div>
            </div>
          </ModalMotion>
        )}
      </AnimatePresence>
    </Portal>
  );
}
