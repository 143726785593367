import { Dialog, DialogPanel, Transition, TransitionChild } from "@headlessui/react";
import { Fragment, createContext, useCallback, useContext, useEffect, useState } from "react";
import { useAppContext } from "./AppContext";
import FullScreenMobileModal from "./FullScreenMobileModal";

const INITIAL_THEME = {
  modalBackgroundColor: "bg-white",
  modalMaxWidth: "sm:max-w-screen-md"
};

export const ModalTheme = createContext({
  theme: { ...INITIAL_THEME },
  onUpdateTheme: (updates) => {
    // eslint-disable-next-line no-console
    console.error("default onUpdateTheme called with updates:", JSON.stringify(updates, null, 2));
  },
  onThemeReset: () => {}
});

/**
 * @deprecated this is a legacy context, do not use with components/v2
 */
export const ModalThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(INITIAL_THEME);

  const onUpdateTheme = useCallback((updates) => setTheme((prev) => ({ ...prev, ...updates })), []);
  const onThemeReset = useCallback(() => setTheme(INITIAL_THEME), []);

  return (
    <ModalTheme.Provider
      value={{
        theme,
        onUpdateTheme,
        onThemeReset
      }}
    >
      {children}
    </ModalTheme.Provider>
  );
};

export const useModalTheme = () => {
  return useContext(ModalTheme);
};

export default function Modal({ children, visible, onDismiss }) {
  const { isNewMobileView } = useAppContext();
  const { theme, onThemeReset } = useModalTheme();

  const dismissModal = () => {
    onDismiss();
    onThemeReset();
  };

  useEffect(() => {
    if (visible) {
      document.body.classList.add("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [visible]);

  return (
    <>
      {isNewMobileView ? (
        <FullScreenMobileModal visible={visible}>{children}</FullScreenMobileModal>
      ) : (
        <Transition.Root show={visible} as={Fragment}>
          <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={dismissModal}>
            <div className="flex items-end justify-center min-h-screen pt-4 text-center sm:block sm:p-0">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <DialogPanel
                  className={`fixed inset-0 ${theme.modalBackgroundColor} bg-opacity-50 transition-opacity`}
                />
              </TransitionChild>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
              </span>

              <TransitionChild
                as={Fragment}
                enter="ease-in duration-300"
                enterFrom="opacity-0 translate-y-full sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-full sm:translate-y-0 sm:scale-95"
              >
                <div
                  className={`relative inline-block align-bottom rounded-t-xl sm:rounded-xl overflow-hidden transform transition-all w-full sm:my-8 sm:align-middle ${theme.modalMaxWidth}`}
                >
                  <div className="max-h-[80vh] sm:max-h-[75vh] 2xl:max-h-[55vh] transition-all overflow-auto">
                    {children}
                  </div>
                </div>
              </TransitionChild>
            </div>
          </Dialog>
        </Transition.Root>
      )}
    </>
  );
}
