import { CanadianProvince, Country, USState } from "@/lib/states-and-territories";

export default function useGeoCoordinates() {
  async function fetchCoordinates(addressToSearch: string): Promise<GeoCoordinates> {
    const encodedAddressToSearch = encodeURIComponent(addressToSearch);
    const response = await fetch(
      `https://api.mapbox.com/search/geocode/v6/forward?q=${encodedAddressToSearch}&access_token=${process.env.NEXT_PUBLIC_MAPBOX_TOKEN}`
    );
    const data = await response.json();
    const coordinates = data.features[0]?.geometry.coordinates;
    if (coordinates && coordinates.length === 2) {
      return { latitude: coordinates[1], longitude: coordinates[0] };
    }
    throw new Error("Coordinates not found for the address");
  }

  async function getStateAndCountryFromUserGeolocation(): Promise<AddressData> {
    const response = await fetch(
      //@ts-ignore
      `https://api.mapbox.com/search/geocode/v6/reverse?longitude=${geolocation.lng}&latitude=${geolocation.lat}&access_token=${process.env.NEXT_PUBLIC_MAPBOX_TOKEN}`
    );
    const data = await response.json();
    const addressData = data.features[0].properties.context;
    return {
      country: addressData.country.name,
      state: addressData.region.name,
      zipCode: addressData.postcode.name
    };
  }

  async function fetchMostAccurateGeoCoordinatesFromAddress(
    address: GeoCoordinatesFromAddressInput
  ): Promise<GeoCoordinates> {
    const fullSearchAddress = getAddressString(address);
    try {
      return await fetchCoordinates(fullSearchAddress);
    } catch (error) {
      const fullSearchAddressWithoutLineTwo = getAddressString(address, false);
      try {
        return await fetchCoordinates(fullSearchAddressWithoutLineTwo);
      } catch (error) {
        return await fetchCoordinates(address.zipCode);
      }
    }
  }

  function getAddressString(address: GeoCoordinatesFromAddressInput, includeLineTwo: boolean = true): string {
    const { lineOne, lineTwo, city, state, zipCode } = address;
    const parts = [lineOne, includeLineTwo && lineTwo, city, state, zipCode];
    return parts.filter(Boolean).join(" ");
  }

  return {
    fetchCoordinates,
    getStateAndCountryFromUserGeolocation,
    fetchMostAccurateGeoCoordinatesFromAddress,
    getAddressString
  };
}

export type GeoCoordinates = {
  latitude: number;
  longitude: number;
};

export type AddressData = {
  country: string;
  state: string;
  zipCode: string;
};

export type GeoCoordinatesFromAddressInput = {
  id?: string;
  addressId?: string;
  recipientName?: string;
  lineOne?: string;
  lineTwo?: string;
  apartment?: string;
  zipCode?: string;
  city?: string;
  state?: USState | CanadianProvince;
  country?: Country;
  deliveryInstructions?: string;
  isDefault?: boolean;
  latitude?: number;
  longitude?: number;
};
