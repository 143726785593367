import { Icons } from "@/images/all";
import CloudFlareImage from "components/CloudFlareImage";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { camelCase, last } from "lodash";
import { useEffect } from "react";
import { trackFBPurchase } from "../../lib/facebook";
import { logPurchase } from "../../lib/firebase";
import { track } from "../../lib/tracking";
import { centsToDecimal, fetchLocal, formatPhone, storeLocal } from "../../lib/util";
import { fetchUserAddresses } from "../account/requests";
import { IMAGESTORAGE as giftcardImageStorage } from "../giftcard/constants";
import {
  CARRY_OUT,
  CATERING,
  DEFAULT_TIMEZONE,
  DELIVERY,
  DELIVERY_INTERVAL,
  ELEMENT_IDS,
  GIFTCARD,
  INITIAL_ORDER,
  PICKUP,
  PICKUP_INTERVAL,
  SHIPPING
} from "./constants";
import {
  fetchNearbyStores,
  fetchNextAvailableDeliveryTimeSlots,
  fetchShippingStore,
  fetchStoreHours
} from "./requests";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(weekOfYear);
dayjs.extend(isBetween);

/*
 * generate time slots after fetching source
 * so it's easier to cycle through in the UI
 * returns by date -> [timeslots]
 *
 * filter away "closures" / past
 */
const generateTimeSlots = (source) => {
  if (!source || source.type === CATERING) {
    return [];
  }

  const interval = fetchLocal("order")?.type == DELIVERY ? DELIVERY_INTERVAL : PICKUP_INTERVAL;
  const timezone = getOrderTimezone();
  const rightNow = dayjs().tz(timezone);
  const dateFormat = "YYYY-MM-DD";

  return source?.businessHours?.openings
    ?.map(({ open, close }) => {
      const [start, end] = [open, close].map((time) => dayjs(time).tz(timezone));
      let timeslots = [];
      let pointer = start.clone();
      while (pointer.isBefore(end)) {
        //only allow timeslots in the future (+3 minutes)
        if (pointer.diff(rightNow, "minutes") > 3) {
          timeslots.push(pointer.clone());
        }

        pointer = pointer.add(interval, "minutes");
      }

      const closures = source?.businessHours?.sourceClosures
        ?.filter(
          ({ eventDate }) => start.format(dateFormat) == dayjs(eventDate, dateFormat).tz(timezone).format(dateFormat)
        )
        ?.map(({ hours }) => [
          dayjs(`${hours.startingDate} ${hours.startingHour}:00:00`, "YYYY-MM-DD HH:mm:ss").tz(timezone),
          dayjs(`${hours.endingDate} ${hours.endHour}:00:00`, "YYYY-MM-DD HH:mm:ss").tz(timezone)
        ]);

      if (!closures?.length) {
        return timeslots;
      }

      return timeslots.filter((time) => {
        return !closures?.find(([start, end]) => dayjs(time).isBetween(start, end, undefined, "[)"));
      });
    })
    ?.filter((timeslots) => timeslots.length);
};

//check if it's open (vs openings)
//check if it's closed (vs closures)
export const findClosure = (businessHours, time) => {
  const closures = businessHours?.sourceClosures || [];
  const openings = businessHours?.openings || [];

  const timezone = getOrderTimezone();
  const dateFormat = "YYYY-MM-DD";
  time = dayjs(time).tz(timezone);

  let twoWeeksAway = false;
  const lastOpenTimeslot = last<any>(openings);
  if (lastOpenTimeslot?.close) {
    twoWeeksAway = time.isAfter(dayjs.tz(lastOpenTimeslot?.close, timezone));
  }

  const isOpen = twoWeeksAway || openings?.find(({ open }) => dayjs(open).tz(timezone).isSame(time, "day"));
  if (!isOpen) {
    return [time, time.clone().endOf("day")];
  }

  const dayClosures = closures
    ?.filter(({ eventDate }) => time.format(dateFormat) == dayjs(eventDate, dateFormat).tz(timezone).format(dateFormat))
    ?.map(({ hours }) => [
      dayjs(`${hours.startingDate} ${hours.startingHour}:00:00`, "YYYY-MM-DD HH:mm:ss").tz(timezone),
      dayjs(`${hours.endingDate} ${hours.endHour}:00:00`, "YYYY-MM-DD HH:mm:ss").tz(timezone)
    ]);

  if (!dayClosures?.length) {
    return false;
  }

  return dayClosures?.find(([start, end]) => dayjs(time).isBetween(start, end, undefined, "[)"));
};

/*
 * so that when customers "order", their "where" is somewhat preselected
 * called when a customer logs in, or adds a new address
 */
const setCustomerDefaults = async () => {
  const customer = fetchLocal("customer");
  if (!customer) {
    return;
  }

  //pickup -> automatically select the closest store
  const userLocation = fetchLocal("userLocation");
  if (userLocation && userLocation?.lat && userLocation?.lng && !customer?.defaultPickup) {
    fetchNearbyStores({
      coordinates: {
        latitude: `${userLocation.lat}`,
        longitude: `${userLocation.lng}`
      }
    }).then((store) =>
      storeLocal("customer", {
        ...fetchLocal("customer"),
        defaultPickup: { store }
      })
    );
  }

  //delivery / shipping -> if there's only ONE address, pick that
  const token = fetchLocal("token");
  if (token && customer) {
    fetchUserAddresses().then((addresses) => {
      if (addresses?.length == 1) {
        const address = addresses[0];
        const info = {
          addressId: address?.addressId
        };
        address["description"] = `${address?.primary}, ${address?.secondary}`;

        fetchNearbyStores({
          googlePlaceId: address?.googlePlaceId
        }).then((store) =>
          storeLocal("customer", {
            ...fetchLocal("customer"),
            defaultDelivery: { address, store, info }
          })
        );

        fetchShippingStore({ googlePlaceId: address?.googlePlaceId }).then((store) =>
          storeLocal("customer", {
            ...fetchLocal("customer"),
            defaultShipping: { address, store, info }
          })
        );
      } else {
        storeLocal("customer", {
          ...fetchLocal("customer"),
          defaultShipping: null,
          defaultDelivery: null
        });
      }
    });
  }
};

// asap or based on source.businesshours
const preSelectWhen = async () => {
  let order = fetchLocal("order");
  const { type, where } = order;
  const storeId = where?.store?.storeId;

  if (type == CATERING || !storeId) {
    return storeLocal("order", { ...order, when: null });
  }

  let asap = await getAsap();
  if (asap) {
    order.when = { date: asap, isAsap: true };
    return storeLocal("order", order);
  }

  const availableDates = generateTimeSlots(where?.source);
  if (!availableDates?.length) {
    return;
  }

  const date = availableDates?.[0]?.[0];
  if (!date) {
    return;
  }

  storeLocal("order", { ...order, when: { date, isAsap: false } });
};

const startOrder = async (type = CARRY_OUT, options = {}) => {
  track({
    event: "trackEvent",
    eventLabel: type,
    eventCategory: "Order type",
    eventAction: "Clicked"
  });
  let order = { ...INITIAL_ORDER, type, ...options };
  const customer = fetchLocal("customer");

  if (customer && !order?.where) {
    switch (type) {
      case PICKUP:
        order.where = customer?.defaultPickup?.store ? customer.defaultPickup : null;
        break;

      case DELIVERY:
        order.where = customer?.defaultDelivery;
        break;

      case SHIPPING:
        order.where = customer?.defaultShipping;
        break;
    }
  }

  storeLocal("order", order);
  if (order.where && ![SHIPPING, CATERING].includes(type)) {
    await preSelectWhen();
  }
};

const renderCreditCardIcon = (brand, className = "") => {
  const icon = {
    visa: Icons.visa,
    mastercard: Icons.mastercard,
    amex: Icons.amex,
    discover: Icons.discover
  }[brand];

  if (!icon) {
    return null;
  }

  return <CloudFlareImage src={icon} width={45} height={28} className={className} />;
};

const getOrderTimezone = () => {
  const { where } = fetchLocal("order") || {};
  return where?.source?.businessHours?.timezone || where?.store?.storeHours?.timezone || DEFAULT_TIMEZONE;
};

const isWithinWeek = () => {
  const { when } = fetchLocal("order") || {};
  const timezone = getOrderTimezone();

  //if there's no "when"... they're still picking out the store..
  if (!when?.date) {
    return true;
  }

  let orderWhen = dayjs(when?.date).tz(timezone).week();
  let orderTime = dayjs().tz(timezone).week();

  if (dayjs().tz(timezone).format("ddd") == "Sun" && parseInt(dayjs().tz("America/Boise").format("H")) < 18) {
    orderTime -= 1; //consider Sunday (before 6pm MST) as the week before
  }

  return orderWhen == orderTime;
};

/*
 there are 2 places where we need ASAP time:
 1. when pre selecting "when"
 2. when the user selects their own "when"
 */
const getAsap = async () => {
  const { where, type } = fetchLocal("order");

  if (type != DELIVERY) {
    return null;
  }

  const addressId = where?.info?.addressId || where?.address?.addressId;
  const storeId = where?.store?.storeId;

  if (!addressId || !storeId) {
    return null; //addressId is user dependent, so no 'asap' for guests?
  }

  const timeSlots = await fetchNextAvailableDeliveryTimeSlots(storeId, addressId);
  const result = timeSlots?.find((slot) => slot.isAvailable)?.startTimestamp;
  return result;
};

/*
 * customer default pickup, delivery, shipping stores are saved to the local storage
 * but.. the saved storeHours & deliveryHours can get stale
 * should update this everytime the customer wants to order (through OrderSelector.js)
 */
const updateCustomerDefaults = async () => {
  let customer = fetchLocal("customer");
  if (!customer) {
    return;
  }

  const customerKeys = ["defaultPickup", "defaultDelivery"]; //, "defaultShipping"
  let storeIds = customerKeys
    .filter((key) => customer[key] && customer[key]?.store?.storeId)
    .map((key) => customer[key].store.storeId);

  if (!storeIds.length) {
    //no defaults
    return;
  }
  const storeByIds = await fetchStoreHours(storeIds);
  customer = fetchLocal("customer"); //updated
  customerKeys.forEach((key) => {
    if (!customer[key]?.store) {
      return;
    }
    const { store } = customer[key];
    customer[key].store = { ...store, ...storeByIds[store?.storeId], lastUpdate: dayjs().format() };
  });
  storeLocal("customer", { ...customer });
};

const productsToTrack = () => {
  let products = fetchLocal("order")?.what || [];
  return products?.map(({ product, meta, price }, position) => ({
    id: product?.productId,
    name: meta?.title,
    price: price / 100,
    category: meta?.category,
    variant: meta.lineItems.join(", "),
    quantity: 1,
    position
  }));
};

export enum CheckoutEventLabel {
  Login = "Login",
  ReviewOrder = "Review Order",
  AddNote = "Add Note",
  PickupDetails = "Pickup Details",
  Tips = "Tips",
  AddPaymentMethod = "Add Payment Method"
}

const trackCheckout = (eventLabel: CheckoutEventLabel, moreData = {}) => {
  const order = fetchLocal("order");

  track({
    event: "checkout",
    eventCategory: "Checkout",
    eventAction: "Viewed",
    eventLabel,
    ecommerce: {
      checkout: {
        actionField: {
          eventLabel,
          ...moreData
        },
        products: productsToTrack(),
        currency: order?.totals?.total?.currency || "USD",
        amount: (order?.totals?.total?.amount || 0) / 100
      }
    }
  });
};

// Custom hook for tracking checkout events. Include this in any component that
// needs to track checkout events and it will fire once after the component
// renders initially. Now we don't have to do complex logic to figure out what
// event to send, yay!!!
const useTrackCheckout = (eventLabel: CheckoutEventLabel, moreData = {}) => {
  useEffect(() => {
    trackCheckout(eventLabel, moreData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const trackGiftcard = (giftCardObj) => {
  const cart = giftCardObj?.apiOrderData;
  const orderId = cart?.orderId;
  const total = cart?.totals?.total?.amount || 0;
  const tax = cart?.totals?.salesTax?.amount?.amount || 0;
  const currency = cart.totals?.total?.currency || "USD";

  trackFBPurchase(orderId, centsToDecimal(total), currency, [giftCardObj?.product?.product?.productId], "product");
  logPurchase(orderId, currency, total, tax);
  trackPurchase({
    id: orderId,
    affiliation: "WEB",
    type: "Giftcard",
    revenue: total / 100,
    tax: tax / 100,
    shipping: 0,
    delivery: 0
  });

  storeLocal(giftcardImageStorage, null);
};

const trackPurchase = (actionField) => {
  const products = productsToTrack();
  const customer = fetchLocal("customer");
  track({
    orderId: actionField?.id,
    event: "ecomm_event",
    ecommerce: {
      purchase: {
        actionField,
        products,
        itemCount: products?.length || 0
      }
    },
    eventLabel: "Purchase",
    eventValue: actionField?.revenue || 0,
    user_data: {
      email: customer?.email || "",
      phone: formatPhone(customer?.phoneNumber || "")
    }
  });
};

const trackPurchaseWrapper = (order) => {
  let flavorIds = [];
  let productIds = [];
  order.items?.forEach((w) => {
    flavorIds.push(...(w?.meta?.flavorIds || []));
    productIds.push(w.product.productId);
  });
  flavorIds = flavorIds.filter((f) => !!f);
  const contentIds = flavorIds.length ? flavorIds : productIds;
  const contentType = flavorIds.length ? "product_group" : "product";
  const total = order.totals?.total?.amount || 0;
  const currency = order.totals?.total?.currency || "USD";

  trackFBPurchase(order.orderId, centsToDecimal(total), currency, contentIds, contentType);
  logPurchase(order.orderId, currency, total, order.totals?.taxes?.amount?.amount);
  trackPurchase({
    id: order.orderId || "",
    affiliation: "WEB",
    type: order.type || "",
    revenue: centsToDecimal(total),
    tax: centsToDecimal(order.totals?.salesTax?.amount?.amount),
    shipping: centsToDecimal(order.totals?.shippingFee?.amount),
    delivery: centsToDecimal(order.totals?.deliveryFee?.amount)
  });
};

const formatFulfillment = (order) => {
  let fulfillment = {};
  switch (order?.type) {
    case CATERING:
      if (order?.when) {
        fulfillment = {
          terminal: {
            isCatering: true,
            name: order?.cateringInfo?.name || "",
            phoneNumber: order?.cateringInfo?.phoneNumber || "",
            pickupAt: dayjs(order?.when?.date).utc().format()
          }
        };
      }
      break;

    case PICKUP:
      if (order?.when) {
        fulfillment = {
          pickup: {
            name: order?.pickup?.name || "",
            customerArrivedDescription: order?.pickup?.vehicle || "",
            pickupAt: dayjs(order?.when?.date).utc().format()
          }
        };
      }
      break;

    case CARRY_OUT:
      if (order?.when) {
        fulfillment = {
          pickup: {
            name: order?.pickup?.name || "",
            pickupAt: dayjs(order?.when?.date).utc().format()
          }
        };
      }
      break;

    case DELIVERY:
      if (order?.when) {
        fulfillment = {
          delivery: {
            name: order.where?.address?.name,
            addressId: order.where?.address?.addressId,
            deliveryWindowStart: dayjs(order?.when?.date).utc().format(),
            deliveryWindowEnd: dayjs(order?.when?.date).utc().add(30, "minutes").format(),
            deliverAsap: order?.when?.isAsap
          }
        };
      }
      break;

    case SHIPPING:
      fulfillment = {
        shipping: {
          rateId: order?.shipping?.rate?.shippingRateId,
          name: order.where?.address?.name,
          addressId: order.where?.address?.addressId
        }
      };
      break;
  }

  return fulfillment;
};

const poll = async (checkSuccess, complete, timedOut, interval, maxAttempts = 5) => {
  if (maxAttempts == 0) {
    if (timedOut) {
      timedOut();
    }
    return;
  }
  if (await checkSuccess()) {
    complete();
    return;
  }

  setTimeout(() => {
    poll(checkSuccess, complete, timedOut, interval, maxAttempts - 1);
  }, interval);
};

export const resizeImageIfNeeded = (url = "", size = 600) => {
  if (!url) {
    return "";
  }

  if (url.includes("generated")) {
    return url;
  }

  return `https://crumbl.video/cdn-cgi/image/width=${size}/${normalizeSrc(url)}`;
};

const normalizeSrc = (src) => {
  return src?.startsWith("/") ? src.slice(1) : src;
};

export const renderOrderIcon = (type) => {
  return (
    {
      [DELIVERY]: <CloudFlareImage width={58} height={33} src={Icons.deliveryIcon} />,
      [PICKUP]: <CloudFlareImage width={38} height={41} src={Icons.curbsideIcon} />,
      [CATERING]: <CloudFlareImage width={39} height={41} src={Icons.cateringIcon} />,
      [GIFTCARD]: <CloudFlareImage width={39} height={40} src={Icons.giftcardIcon} />,
      [SHIPPING]: <CloudFlareImage width={41} height={41} src={Icons.shippingIcon} />
    }[type] || <CloudFlareImage width={58} height={33} src={Icons.deliveryIcon} />
  );
};

export const getCategoryNavElementId = (category) => `${ELEMENT_IDS.catNav}-${camelCase(category)}`;

export {
  formatFulfillment,
  generateTimeSlots,
  getAsap,
  getOrderTimezone,
  isWithinWeek,
  poll,
  preSelectWhen,
  productsToTrack,
  renderCreditCardIcon,
  setCustomerDefaults,
  startOrder,
  trackCheckout,
  trackPurchase,
  trackPurchaseWrapper,
  updateCustomerDefaults,
  useTrackCheckout
};
