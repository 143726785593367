import { storeLocal } from "../../lib/util";
import { setToken } from "../../lib/xhr_legacy";

const logout = () => {
  [
    "accessToken",
    "chartToken",
    "customer",
    "giftcard-order",
    "name",
    "nextWeekWarning",
    "order",
    "phoneNumber",
    "refreshToken",
    "sundayWarning",
    "userId",
    "userLocation"
  ].map((v) => storeLocal(v, null));
  setToken(null);
};

export { logout };
